import React, { useEffect } from 'react'
import {
  ButtonElement,
  CommonPageHeader,
  DateInputField,
  dropdownFiledTheme,
  dropdownOptionStyles,
  DropZone,
  TextInput,
  TimePicker,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import classNames from 'classnames'
import viewIcon from '@/assets/icons/view-icon.svg'
import submitIcon from '@/assets/icons/submit-nav-icon.svg'
import { useRouter } from '@/react/hooks'
import { default as ReactSelect } from 'react-select'
import { EditIcon } from '@/react/componentAssets'
import { RoleAndProjectPicker } from '@screens/CompanyNews/CompanyNewsCreateEdit/components'
import moment from 'moment'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { nonAdminRedirects } from '@/utils/roleRedirects'
import { LimitedTimezonesForSelect } from '@/utils/timezonesRelated'
import { InfoModal } from '@/react/componentAssets/InfoModal'

export const CompanyNewsCreateEdit = () => {
  const { $rootScope, Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const app = $rootScope.mobile_apps.find((obj) => {
    return obj.id === Number(stateService.params.app)
  })

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [renderPage, setRenderPage] = React.useState<boolean>(
    stateService.current.name === 'app.company_news.add',
  )
  const [title, setTitle] = React.useState<string>('')
  const [description, setDescription] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [fileId, setFileId] = React.useState(null)
  const [time, setTime] = React.useState<string | undefined>()
  const [dateStart, setDateStart] = React.useState(null)
  const [dateEnd, setDateEnd] = React.useState(null)
  const [timezone, setTimezone] = React.useState({
    value: 'US/Central',
    label: 'CT',
  })
  const [rolePickerOpen, setRolePickerOpen] = React.useState<boolean>(false)
  const [infoOpen, setInfoOpen] = React.useState<boolean>(false)
  const [dateInfoOpen, setDateInfoOpen] = React.useState<boolean>(false)
  const [rolePicked, setRolePicked] = React.useState<string[]>([])
  const [projectPickerOpen, setProjectPickerOpen] =
    React.useState<boolean>(false)
  const [projectPicked, setProjectPicked] = React.useState<string[]>([])
  const [alertType, setAlertType] = React.useState<
    { value: string; label: string }[]
  >([])

  const sendImage = async (file) => {
    if (file) {
      const { data } = await Api.uploadImage(file, {})
      setFileId(data.id)
      setFile(file)
    } else {
      setFileId(null)
      setFile(null)
    }
  }

  const sendLogic = async () => {
    const data = {
      application: stateService.params.app,
      images: fileId ? [fileId] : [],
      videos: [],
      client: CurrentUser.getClientId(),
      start_date:
        (dateStart
          ? dateStart.format('MM/DD/YYYY')
          : moment().format('MM/DD/YYYY')) +
        ' ' +
        time,
      end_date: dateEnd.format('MM/DD/YYYY') + ' 23:59',
      title: title,
      description: description,
      assigned_roles: rolePicked,
      projects: projectPicked,
      timezone: timezone.value,
      alert_types: alertType.map((selected) => selected.value),
    }
    if (stateService.current.name === 'app.company_news.edit') {
      data.id = stateService.params.newsID
      const { data: res } = await Api.patch(
        `news/${stateService.params.newsID}`,
        data,
      )
    } else {
      const { data: res } = await Api.post('news', data)
    }
    stateService.go('app.company_news.list')
  }

  const startingAlertTypes = (
    alerts: string[],
  ): { value: string; label: string }[] => {
    const selectedData: { value: string; label: string }[] = []
    alerts.forEach((alert) => {
      switch (alert) {
        case 'push':
          selectedData.push({ label: 'Push Notification', value: 'push' })
          break
        case 'email':
          selectedData.push({ label: 'Email', value: 'email' })
          break
        case 'text':
          selectedData.push({ label: 'Text', value: 'text' })
          break
      }
    })
    return selectedData
  }

  const getTimezone = (timezone): { value: string; label: string } => {
    let returnValue = {
      value: 'US/Central',
      label: 'CT',
    }
    LimitedTimezonesForSelect.forEach((timezoneOption) => {
      if (timezone === timezoneOption.value) returnValue = timezoneOption
    })
    return returnValue
  }

  const getEditData = async () => {
    const { data: res } = await Api.get(`news/${stateService.params.newsID}`)
    const startDate = res.start_date.split(' ')
    const endDate = res.end_date.split(' ')
    setDateStart(moment(startDate[0], 'MM/DD/YYYY'))
    setDateEnd(moment(endDate[0], 'MM/DD/YYYY'))
    setTitle(res.title)
    setDescription(res.description)
    setProjectPicked(res.projects.map((project) => project.id))
    setRolePicked(res.assigned_roles)
    setAlertType(startingAlertTypes(res.alert_types))
    setTime(startDate[1])
    if (res.timezone) setTimezone(getTimezone(res.timezone))
    if (res.images[0]) setFile(res.images[0])
    if (res.images[0]) setFileId(res.images[0].id)
    setRenderPage(true)
  }

  React.useEffect(() => {
    nonAdminRedirects(CurrentUser, stateService, app.id)
    if (stateService.current.name === 'app.company_news.edit') {
      getEditData()
    }
  }, [])

  if (!renderPage) return null

  return (
    <>
      <CommonPageHeader headerText="Company News" />
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'app.company_news.list'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.company_news.list' ||
                stateService.current.name === 'app.company_news.edit',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        <UISref to={'app.company_news.add'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'app.company_news.add',
            })}
          >
            <img className="nav__icon" src={submitIcon} alt="view nav__icon" />
            <p>Add</p>
          </a>
        </UISref>
      </div>
      <main css={baseStyle}>
        <div className="column column-left">
          <label className="main-label">Title</label>
          <TextInput
            waitTime={200}
            width={'100%'}
            height={36}
            placeholder={''}
            defaultValue={title}
            valueCallback={(value) => {
              setTitle(value)
            }}
            error={!title.length}
            textLimit={255}
            textarea={true}
            textLimitDisplay={true}
          />
          <label className="main-label">Description</label>
          <TextInput
            waitTime={200}
            width={'100%'}
            placeholder={''}
            defaultValue={description}
            valueCallback={(value) => {
              setDescription(value)
            }}
            error={!description.length}
            textarea={true}
            height={358}
          />
        </div>
        <div className="column solid-column">
          <DropZone
            accept="image/png, image/gif, image/jpeg"
            imageDisplay={true}
            fileSetStorage={sendImage}
            fileStorage={file}
            addCallback={() => {}}
            removeCallback={() => {}}
            dropMessage={
              <p className="smaller-drop-text">Drop the image here ...</p>
            }
            hoverMessage={
              <p className="smaller-drop-text">
                {' '}
                <b>Choose an image</b> or drag it here.
              </p>
            }
            additionalStyles={css({
              width: '100%',
              height: 130,
              '.prop-name': {
                fontSize: '15px',
                '.smaller-drop-text': {
                  fontSize: '15px',
                },
                '.upload-icon': {
                  width: '15% !important',
                },
              },
            })}
          />
          <label className="main-label">Who will see this news?</label>
          <div className="project-and-roles left-spacing">
            <div className="text">{app.projects_display}</div>
            <EditIcon
              additionalStyles={css({
                marginRight: 5,
                marginLeft: 5,
                cursor: 'pointer',
              })}
              onClick={() => {
                setProjectPickerOpen(true)
              }}
              color={projectPicked.length ? variableColor : '#C80404'}
            />
            <div className="counters">{projectPicked.length}</div>
            <div className="text">Roles</div>
            <EditIcon
              additionalStyles={css({
                marginRight: 5,
                marginLeft: 5,
                cursor: 'pointer',
              })}
              onClick={() => {
                setRolePickerOpen(true)
              }}
              color={rolePicked.length ? variableColor : '#C80404'}
            />
            <div className="counters">{rolePicked.length}</div>
          </div>
          <label className="main-label" css={css({ marginTop: 8 })}>
            When will they see this news?
          </label>
          <div className="date-and-time left-spacing">
            <div className="date-row">
              <div className="date-labels">Start Date</div>
              <div className="label-with-info date-labels">
                <div>End Date</div>
                <div
                  onClick={() => {
                    setDateInfoOpen(true)
                  }}
                  className="info-button"
                >
                  <div>i</div>
                  {dateInfoOpen && (
                    <InfoModal
                      setModalOpen={setDateInfoOpen}
                      message={
                        'End Date is only applicable for displaying Company News on the mobile apps.'
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="date-and-time left-spacing">
            <div className="date-row">
              <DateInputField
                defDate={dateStart}
                ohChange={(value) => {
                  setDateStart(value)
                }}
                width={'48%'}
                maxDate={dateEnd}
                error={!dateStart}
              />
              <DateInputField
                defDate={dateEnd}
                ohChange={(value) => {
                  setDateEnd(value)
                }}
                width={'48%'}
                minDate={dateStart}
                error={!dateEnd}
              />
            </div>
          </div>
          <div className="time-select-holder left-spacing">
            <div>Start Time</div>
            <TimePicker
              startingOptionString={time}
              onChange={setTime}
              onTimezoneChange={setTimezone}
              startingTimezone={timezone}
            ></TimePicker>
          </div>
          <div className="label-with-info">
            <label className="main-label">
              What type of additional alert will they receive?
            </label>
            <div
              onClick={() => {
                setInfoOpen(true)
              }}
              className="info-button"
            >
              <div>i</div>
              {infoOpen && (
                <InfoModal
                  setModalOpen={setInfoOpen}
                  message={
                    'These are alerts in addition to the company news that will appear when the user opens the app.'
                  }
                />
              )}
            </div>
          </div>
          <div className="alert-type-and-save left-spacing">
            <div className="select-wrapper">
              <ReactSelect
                defaultValue={alertType}
                isMulti
                options={[
                  { label: 'Push Notification', value: 'push' },
                  { label: 'Email', value: 'email' },
                  { label: 'Text', value: 'text' },
                ]}
                onChange={(value) => {
                  setAlertType(value)
                }}
                styles={dropdownOptionStyles(variableColor)}
                theme={(theme) => dropdownFiledTheme(theme, variableColor)}
              />
            </div>
            <ButtonElement
              text={'Save'}
              buttonType={'submit'}
              disabled={
                !(
                  title.length &&
                  description.length &&
                  dateStart &&
                  dateEnd &&
                  rolePicked.length &&
                  projectPicked.length &&
                  dateStart <= dateEnd
                )
              }
              functionToTrigger={() => {
                sendLogic()
              }}
            />
          </div>
        </div>
      </main>
      {!rolePickerOpen ? null : (
        <RoleAndProjectPicker
          setPopUp={setRolePickerOpen}
          onChange={setRolePicked}
          startingValues={rolePicked}
        />
      )}
      {!projectPickerOpen ? null : (
        <RoleAndProjectPicker
          setPopUp={setProjectPickerOpen}
          onChange={setProjectPicked}
          startingValues={projectPicked}
          isProjects={true}
          projectsVariable={app.projects_display}
        />
      )}
      <div className="spacer" css={css({ height: 100 })}></div>
    </>
  )
}

const baseStyle = css({
  background: '#fff',
  width: 1129,
  maxWidth: '100%',
  minHeight: 20,
  padding: 25,
  display: 'flex',
  '.column': {
    minHeight: 460,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    '.main-label': {
      textDecoration: 'underline',
    },
    '.project-and-roles': {
      display: 'flex',
      '.counters': {
        marginRight: 10,
      },
    },
    '.date-and-time': {
      width: '100%',
      '.date-row': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '.date-labels': {
          width: '48%',
          alignItems: 'baseline',
        },
      },
    },
    '.label-with-info': {
      position: 'relative',
      display: 'flex',
      '.info-button': {
        borderRadius: '50%',
        color: 'white',
        backgroundColor: '#888888',
        width: 12,
        height: 12,
        marginLeft: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 8,
        lineHeight: '8px',
        cursor: 'pointer',
      },
      '.modal__wrapper': {
        width: 185,
        position: 'relative',
      },
    },
    '.alert-type-and-save': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
      '.select-wrapper': {
        width: 260,
      },
    },
    '.left-spacing': {
      paddingLeft: 15,
    },
  },
  '.solid-column': {
    height: 460,
  },
  '.column-left': {
    marginRight: 25,
  },
})

const tabButtonList = css({
  width: '100%',
  marginTop: '2vw',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginRight: '15px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginRight: '0 !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '15px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '5px',
      marginRight: '15px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
