import { makeLoadResolver, resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function observationsConfig($stateProvider) {
  $stateProvider
    .state('app.observations.view', {
      template: `<view-base/>`,
      controller: function () {},
      url: '/observations/:observation/view?previousPageNumber&previousOrder&previousReverse&previousSearch&f_id',
      resolve: { ...resolvers.appResolver, ...resolvers.PreviousStateResolver },
    })
    .state('share.observation', {
      url: '/share/observation/:key',
      template: '<view-base/>',
      controller: function () {},
    })
    .state('app.batch_reports.view', {
      url: '/batch_reports/:batch_report?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<view-base/>',
      controller: function () {},
      resolve: resolvers.appResolver,
    })
    .state('share.batch_report', {
      url: '/share/batch_report/:key',
      template: '<view-base/>',
      controller: function () {},
    })
    .state('share.answer', {
      url: '/share/answer/:key',
      template: '<answer-read-only/>',
      controller: function () {},
    })
    .state('assigned.answer', {
      template: '<assigned-external/>',
      url: '/assigned/answer/:key/:ehash/',
      controller: function () {},
      params: {
        key: {
          value: '',
          dynamic: true,
        },
        ehash: {
          value: '',
          dynamic: true,
        },
      },
    })
    .state('app.observations.edit', {
      template: '<edit-base/>',
      url: '/observations/:observation/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
      controller: function () {},
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.writeOnlyViewResolver,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('admin.party_observed_create', {
      url: '/party_observed_create',
      template: '<create-party-observed/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.party_observed_bulk', {
      url: '/party_observed_bulk',
      template: '<bulk-party-observed/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.party_observed_list', {
      url: '/party_observed_list?pageNumber&order&search',
      params: {
        order: { dynamic: true, value: 'name' },
      },
      template: '<list-party-observed/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('app.observations.list', {
      template: '<observations-list-component></observations-list-component>',
      url: '/observations?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.observations.deleted_observations', {
      template:
        '<deleted-observations-list-component></deleted-observations-list-component>',
      url: '/observations/deleted?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.observations.add', {
      template: '<observation-add-component></observation-add-component>',
      url: '/observations/add',
    })
    .state('app.questions.add', {
      url: '/questions/my/add?categoryId',
      template:
        '<observation-custom-questions-component></observation-custom-questions-component>',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewMyQuestions'),
      },
    })
    .state('app.questions.edit', {
      template:
        '<observation-edit-question-component></observation-edit-question-component>',
      url: '/questions/my/edit/:question?previousPageNumber&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewMyQuestions'),
      },
    })
    .state('app.batch_reports.list', {
      url: '/batch_reports?pageNumber&order&search&reverse',
      template: '<batch-observations-component></batch-observations-component>',
      resolve: {
        ...resolvers.appResolver,
      },
    })
    .state('app.observation_templates', {
      url: '/observations/settings',
      template: '<observation-settings-component />',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('app.categories.list', {
      url: '/categories?pageNumber&order&reverse&search',
      template: '<assign-projects-to-categories-component />',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategories'),
      },
    })
    .state('app.categories.by_project', {
      url: '/categories-by-project?pageNumber&order&reverse&search',
      template: '<assign-categories-to-project-component />',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategories'),
      },
    })
    .state('app.categories.groups', {
      url: '/categories/groups?pageNumber&order&reverse&search',
      template: '<category-groups-component />',
      controller: 'CategoryGroupsCtrl',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategoryGroups'),
      },
    })
}
