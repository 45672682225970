import {
  ButtonElement,
  TableHeaders,
  AdvancedFilter,
  useAngularServices,
  getFilterParam,
} from '@/react/components'
import { DocumentationRow, DocumentationTable } from './DocumentationRow'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../../components'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { encodeString } from '@/utils/additionalEncodeLogic'

export const DocumentationTableBody = ({
  setOrder,
  page,
  setPage,
  search,
  order,
  setSelectedItems,
  selectedItems,
  setListCount,
  listCount,
  isChecked,
  setChecked,
  setSearch,
}) => {
  const { stateService } = useRouter()
  const { Api, Notification, CurrentUser } = useAngularServices()
  const [isAllChecked, setAllChecked] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [deleteAction, setDeleteAction] = useState(false)
  const [isDeleteButtonClicked, setDeleteButtonClicked] = useState(false)
  const [isExportPDFAvailable, setExportPDFAvailable] = useState(false)
  const [isDeleteAvailable, setDeleteAvailable] = useState(false)
  const [advancedFilters, setAdvancedFilters] = useState(
    getFilterStates(stateService),
  )

  const [documentationList, setDocumentationList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  useEffect(() => {
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [])

  useEffect(() => {
    let isMounted = true

    const getTrainingDocumentations = async (page, search) => {
      const params = {
        page: page,
        order: order,
        search: search,
        page_size: 20,
      }

      let stringParams = ''

      Object.entries(params).forEach((param) => {
        stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
      })

      Object.entries(advancedFilters).forEach((param) => {
        if (param[1].type && param[1].string) {
          stringParams +=
            getFilterParam(
              param[0],
              param[1].type,
              param[1].string,
              param[1].secondType,
              param[1].secondString,
            ) + '&'
        }
      })

      const { data: result } = await Api.getWithoutDeletedParamV2(
        'trainings/documentation',
        `${stringParams.slice(0, -1)}&eq(deleted,false)`,
      )

      if (isMounted) {
        setListCount(result.count)
        setDocumentationList(result.results)
        if (isChecked) {
          setSelectedItems(result.results)
        }
      }
      setRefresh(false)
    }
    getTrainingDocumentations(page, search)

    return () => {
      isMounted = false
    }
  }, [page, search, advancedFilters, order, deleteAction, refresh])

  useEffect(() => {
    setDeleteAvailable(
      CurrentUser.getRole() !== 'trainee' &&
        CurrentUser.getRole() !== 'client_user' &&
        CurrentUser.getRole() !== 'supervisor' &&
        CurrentUser.getRole() !== 'read_only',
    )
  }, [])

  const handleCheckedAll = async () => {
    const selectedItems = isChecked ? [] : documentationList

    if (listCount >= 20 && selectedItems.length === documentationList.length) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }

    setSelectedItems(selectedItems)
    setChecked(!isChecked)
  }

  const handleChecked = (item) => {
    const selectedIds = selectedItems.map((el) => el.id)
    if (selectedIds.includes(item.id)) {
      setSelectedItems(selectedItems.filter((el) => el.id !== item.id))
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
  }

  const downloadFile = (fileUrl: string, fileName: string) => {
    const anchor = document.createElement('a')
    anchor.href = fileUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
  }

  useEffect(() => {
    setExportPDFAvailable(
      selectedItems.map((item) => item?.certificate?.id).length > 0 ||
        isChecked,
    )
  }, [selectedItems])

  const handleExportPDF = async () => {
    setActionsClicked(false)
    const certificateIds = selectedItems.map((item) => item?.certificate?.id)

    if (isAllChecked) {
      try {
        const { data: createFileResponse } = await Api.getWithoutDeletedParamV2(
          'trainings/documentation/export_all',
          {
            search,
          },
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.certificates_zip_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Certificates')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time + 10000)
      } catch {
        Notification.warning('Creating of zip file failed')
      }
    }

    if (certificateIds.length && !isAllChecked) {
      try {
        const { data: createFileResponse } = await Api.postV2(
          'trainings/documentation/export',
          {
            certificate_ids: certificateIds,
          },
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.certificates_zip_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Certificates')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time + 1100)
      } catch {
        Notification.warning('Creating of zip file failed')
      }
    }
    setSelectedItems([])
    setChecked(false)
  }

  const handleDeleteAction = async () => {
    setDeleteAction(true)
    setDeleteButtonClicked(false)

    // Separate v1 and v2 items
    const v1Items = selectedItems.filter((item) => item.api_version === 'v1')
    const v2Items = selectedItems.filter(
      (item) => item.api_version === 'v2' && item.editable,
    )

    const v1Ids = v1Items.map((item) => item.id)
    const v2Ids = v2Items.map((item) => item.training?.id || item.id)

    if (!v1Ids.length && !v2Ids.length && selectedItems.length) {
      Notification.danger('The selected documentation may not be deleted.')
    }

    if ((v1Ids.length || v2Ids.length) && !isAllChecked) {
      for (const id of v1Ids) {
        try {
          await Api.Jobs.delete({ id })
        } catch {
          Notification.danger('Failed to delete training documentation')
        }
      }

      for (const id of v2Ids) {
        try {
          await Api.TrainingsDocumentation.delete({ id })
        } catch {
          Notification.danger('Failed to delete training documentation')
        }
      }

      setDocumentationList(
        documentationList.filter(
          (doc) =>
            !v1Ids.includes(doc.id) &&
            !v2Ids.includes(doc.training?.id || doc.id),
        ),
      )
    }

    if (isAllChecked) {
      const params = {
        search,
      }

      let stringParams = ''

      Object.entries(params).forEach((param) => {
        stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
      })

      Object.entries(advancedFilters).forEach((param) => {
        if (param[1].type && param[1].string) {
          stringParams +=
            getFilterParam(
              param[0],
              param[1].type,
              param[1].string,
              param[1].secondType,
              param[1].secondString,
            ) + '&'
        }
      })
      try {
        await Api.deleteV2(
          'trainings/documentation/delete_all',
          stringParams.slice(0, -1),
        )
      } catch {
        Notification.danger('The selected documentation may not be deleted.')
      }
    }

    setSelectedItems([])
    setChecked(false)
    setRefresh(true)
    setDeleteAction(false)
  }

  const popupRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDeleteButtonClicked(false)
      }
    }

    if (isDeleteButtonClicked) {
      setDeleteButtonClicked(true)
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isDeleteButtonClicked])

  const paginationReset = () => {
    setPage(1)
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '60px',
        minWidth: '60px',
        textAlign: 'left',
        paddingLeft: '12px',
        paddingRight: '10px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingBottom: '4px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isDeleteAvailable && (
                <div
                  className="action__text"
                  onClick={() => {
                    setActionsClicked(false)
                    setDeleteButtonClicked(true)
                  }}
                >
                  Delete
                </div>
              )}
              {isExportPDFAvailable && (
                <div
                  className="action__text dark"
                  onClick={() => {
                    handleExportPDF()
                  }}
                >
                  Export PDF
                </div>
              )}
            </div>
          )}
          {isDeleteButtonClicked && (selectedItems.length || isChecked) && (
            <div className="delete__popup" ref={popupRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setDeleteButtonClicked(false)
                }}
              />
              <div className="popup__title">
                Delete {isChecked ? listCount : selectedItems.length}{' '}
                {(isChecked ? listCount : selectedItems.length) === 1
                  ? 'Training'
                  : 'Trainings'}
                ?
              </div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  handleDeleteAction()
                }}
              />
            </div>
          )}
          {isDeleteButtonClicked && !selectedItems.length && !isChecked && (
            <div className="delete__popup" ref={popupRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setDeleteButtonClicked(false)
                  setActionsClicked(false)
                }}
              />
              <div className="popup__title">
                Please select at least one item to be deleted.
              </div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  setDeleteButtonClicked(false)
                  setActionsClicked(false)
                }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      className: 'table__header trainee',
      name: 'Trainee',
      type: 'name',
      additionalStyles: {
        width: '145px',
        minWidth: '145px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'ID',
      type: 'employee_id',
      additionalStyles: {
        width: '66px',
        minWidth: '66px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'employee_id'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Topic',
      type: 'training_topic',
      additionalStyles: {
        width: '230px',
        minWidth: '230px',
        textAlign: 'left',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'training_topic'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trained',
      type: 'date_trained',
      additionalStyles: {
        minWidth: '95px',
        width: '95px',
        textAlign: 'center',
        paddingRight: '7px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
      },
    },
    {
      className: 'table__header',
      name: 'Expiring',
      type: 'date_training_expires',
      filterHeader: true,
      additionalStyles: {
        minWidth: '95px',
        width: '95px',
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Trainer',
      type: 'trained_by',
      filterHeader: true,
      additionalStyles: {
        width: '148px',
        minWidth: '148px',
        textAlign: 'center',
        paddingLeft: '0px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '45px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trained_by'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company',
      filterHeader: true,
      additionalStyles: {
        width: '178px',
        minWidth: '178px',
        textAlign: 'center',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '60px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'company'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trade',
      type: 'trade',
      filterHeader: true,
      additionalStyles: {
        width: '128px',
        minWidth: '128px',
        paddingLeft: 0,
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '49px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '5px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trade'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
  ]

  return (
    <table className="documentation__table">
      <TableHeaders
        headers={TABLE_HEADER_SETTINGS}
        callback={setOrder}
        popUpLocation={false}
        updateUrl={false}
        order={order}
        setOrder={setOrder}
      />
      <tbody>
        {documentationList.map((documentation, index) => (
          <DocumentationRow
            handleChecked={handleChecked}
            documentation={documentation}
            index={index}
            selectedItems={selectedItems}
            search={search}
            page={page}
          />
        ))}
      </tbody>
    </table>
  )
}

function getFilterStates(stateService) {
  return {
    name: JSON.parse(stateService.params.name || '{}'),
    company: JSON.parse(stateService.params.company || '{}'),
    training_topic: JSON.parse(stateService.params.training_topic || '{}'),
    trained_by: JSON.parse(stateService.params.trained_by || '{}'),
    employee_id: JSON.parse(stateService.params.employee_id || '{}'),
    trade: JSON.parse(stateService.params.trade || '{}'),
  }
}
