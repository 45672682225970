import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import React, { useEffect, useMemo, useState } from 'react'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { singleHeaderTypes } from '../../components'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import { UISref } from '@/react/components/UISref'
import { InformationPopUp } from './components/InformationPopUp'
import CircularProgress from '@mui/material/CircularProgress'
import { ExportRow } from './components/ExportRow'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const ExportListBase = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser, Notification, $rootScope } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('-date_created')
  const [isPopUpVisible, setPopUpVisible] = useState(false)
  const [exportList, setExportList] = useState([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isAllChecked, setAllChecked] = useState(false)
  const [count, setCount] = useState(20)
  const PAGE_LENGTH = 20

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const handleAllChecked = () => {
    const selectedItems = isAllChecked
      ? []
      : exportList.map((report) => report.id)

    setAllChecked(!isAllChecked)
    setSelectedIds(selectedItems)
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        minWidth: '32px',
        width: '32px',
        textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '30px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleAllChecked}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </div>
      ),
    },
    {
      className: 'table__header title__column',
      name: 'Title',
      type: 'report__title',
      additionalStyles: {
        minWidth: '289px',
        width: '289px',
        textAlign: 'left',
        padding: 0,
        p: {
          display: 'flex',
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'report__title'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Template',
      type: 'form__name',
      additionalStyles: {
        minWidth: '194px',
        width: '194px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'form__name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Name',
      type: 'user__first_name',
      additionalStyles: {
        minWidth: '126px',
        width: '126px',
        padding: 0,

        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'user__first_name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company_name',
      additionalStyles: {
        minWidth: '208px',
        width: '208px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'company_name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: configurableNamesList.projects_display,
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        minWidth: '117px',
        width: '117px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'project__name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: 'Created',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        minWidth: '66px',
        width: '66px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '70px',
          },
        },
      },
    },
  ]

  useEffect(() => {
    const getTableData = async () => {
      const { app, formId, startDate, endDate } = stateService.params
      try {
        const { data } = await Api.putWithParams(
          'reports/select/',
          {},
          {
            form: formId,
            application: app,
            order: order,
            search: search,
            page: page,
            start_date: startDate,
            end_date: endDate,
            deleted: 'false',
            is_external_link_origin: 'false',
            is_draft: false,
          },
        )
        setExportList(data.results)
        setCount(data.count)
      } catch {
        console.log('error')
      }
    }

    getTableData()
  }, [search, order, page])

  const downloadFile = (fileUrl: string) => {
    const anchor = document.createElement('a')
    anchor.href = fileUrl
    anchor.download = fileUrl
    anchor.target = '_blank'
    anchor.click()
  }

  const handleDownload = async () => {
    if (!isAllChecked) {
      setLoading(true)
      try {
        const { data: createFileResponse } = await Api.post('reports/export', {
          reports: selectedIds,
        })

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } = await Api.get(`reports/xls`, {
              xls_id: createFileResponse.xls_id,
            })

            downloadFile(fileResponseURL?.file_url)
          } catch {
            Notification.warning('Failed to get file url for download')
            setLoading(false)
          } finally {
            setLoading(false)
          }
        }, createFileResponse.time * 1000)
      } catch {
        console.log('error')
        setLoading(false)
      }
    } else {
      setLoading(true)
      try {
        const { startDate, endDate } = stateService.params
        const { data: createFileResponse } = await Api.get('reports/export', {
          form: stateService.params.formId,
          search: search,
          start_date: startDate,
          end_date: endDate,
        })

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } = await Api.get(`reports/xls`, {
              xls_id: createFileResponse.xls_id,
            })

            downloadFile(fileResponseURL?.file_url)
          } catch {
            Notification.warning('Failed to get file url for download')
            setLoading(false)
          } finally {
            setLoading(false)
          }
        }, createFileResponse.time * 1000)
      } catch {
        console.log('error')
        setLoading(false)
      }
    }
    setPopUpVisible(true)
  }

  const handleCheck = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id))
      setAllChecked(false)
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(variableColor, isSafari)

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={`${configurableNamesList.mobile_form} Reports`}
      />
      <MobileFormsNavBar />
      <div className="export-page__wrapper">
        <div className="top__container">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            onClear={() => {
              setAllChecked(false)
            }}
          />
          <div className="buttons__row">
            <ButtonElement
              text="Back"
              functionToTrigger={() => {
                stateService.go('app.forms.download_report_data')
              }}
            />
            <button
              className="button__wrapper"
              onClick={handleDownload}
              disabled={!selectedIds.length || isLoading}
            >
              {isLoading && (
                <CircularProgress size={'12px'} style={{ color: '#fff' }} />
              )}
              <p className="button__text">Download .XLS</p>
            </button>
            {isPopUpVisible && (
              <InformationPopUp setPopUpVisible={setPopUpVisible} />
            )}
          </div>
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {exportList.map((report, index) => (
                <ExportRow
                  key={index}
                  report={report}
                  index={index}
                  handleCheck={handleCheck}
                  selectedIds={selectedIds}
                  isAllChecked={isAllChecked}
                />
              ))}
            </tbody>
          </table>
          <div className="page-switcher-base">
            <PageSwitcher
              pageLength={PAGE_LENGTH}
              listCount={count}
              currentPage={page}
              callback={setPage}
              updateUrl={false}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const getBaseStyle = (variableColor, isSafari) => {
  return css({
    height: '1250px',
    '.export-page__wrapper': {
      background: '#fff',
      paddingInline: '12px',
      paddingBottom: '12px',
    },
    '@media(max-width: 1339px)': {
      '.table__wrapper': {
        overflow: 'scroll',
      },
    },
    '@media(min-width: 1345px)': {
      '.export-page__wrapper': {
        width: '1130px',
      },
    },
    '@media(max-width: 610px)': {
      '.top__container': {
        flexDirection: 'column',
        gap: '10px',
      },
      '.buttons__row': {
        gap: '50px !important',
      },
    },
    '.top__container': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '20px',
    },
    '.title': {
      color: '#3980CE',
      cursor: 'pointer',
      maxWidth: '289px',
    },
    '.template': {
      maxWidth: '194px',
    },
    '.name': {
      maxWidth: '126px',
    },
    '.company': {
      maxWidth: '208px',
    },
    '.project': {
      maxWidth: '117px',
    },
    '.buttons__row': {
      display: 'flex',
      position: 'relative',
      gap: '10px',
    },
    '.button__wrapper': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      height: '28px',
      width: '135px',
      backgroundColor: variableColor,
      textAlign: 'center',
      gap: '10px',
      border: 'none',
    },
    '.button__wrapper[disabled]': {
      background: 'grey',
      cursor: 'default',
    },
    '.button__text': {
      color: '#fff',
      margin: 0,
      fontSize: '12px',
    },
    '.checkbox-container': {
      paddingLeft: '10px',
      marginBottom: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.checkmark__td': {
      paddingLeft: '15px',
    },
    '.action': {
      marginTop: '3px',
      cursor: 'pointer',
    },
    '.documentation__row': {
      color: '#575757',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.view__td': {
      cursor: 'pointer',
      textAlign: 'center',
    },
    '.title__text': {
      margin: '0',
      maxHeight: '40px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.name__fullname': {
      left: '530px',
    },
    '.company__fullname': {
      left: '580px',
    },
    '.project__fullname': {
      left: '850px',
    },
    '#search': {
      width: '264px',
    },
  })
}
