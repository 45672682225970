import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function appsCategoriesConfig($stateProvider) {
  $stateProvider
    .state('app.categories', {
      abstract: true,
      data: { pageTitle: 'Categories' },
      templateUrl: 'app/views/common/uiview.html',
      resolve: resolvers.appResolver,
    })
    .state('app.categories.assign_projects', {
      url: '/categories/:category/projects?previousPageNumber&previousOrder&previousReverse?previousSearch',
      controller: 'CategoryAssignProjectsCtrl',
      templateUrl: 'app/views/category_assign_projects.html',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategories'),
      },
    })
    .state('app.categories.assign', {
      url: '/categories/:project/assign?previousPageNumber&previousOrder&previousReverse?previousSearch',
      templateUrl: 'app/views/category_assign.html',
      controller: 'CategoryAssignCtrl',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategories'),
      },
    })
    .state('app.categories.group_assign', {
      url: '/categories/groups/:group/assign?previousPageNumber&previousOrder&previousReverse?previousSearch',
      templateUrl: 'app/views/category_group_assign.html',
      controller: 'CategoryGroupAssignCtrl',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewCategoryGroups'),
      },
    })
    .state('app.categories.custom', {
      url: '/categories/custom??pageNumber&order&reverse&search',
      templateUrl: 'app/views/category_custom.html',
      controller: 'CategoryCustomCtrl',
      resolve: { ...resolvers.appResolver },
    })
    .state('app.categories.custom_projects', {
      url: '/categories/custom/:category/projects?previousPageNumber&previousOrder&previousReverse?previousSearch',
      templateUrl: 'app/views/category_custom_projects.html',
      controller: 'CategoryCustomProjectsCtrl',
      resolve: resolvers.appResolver,
    })
}
